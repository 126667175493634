import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ; 

export default {
  getUserAdminList(params){ //console.log(params); 
    return axios.post(url + '/aacore/user/list', params ).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },
  createAdminUser(params){ //console.log(params);
    return axios.post(url + '/aacore/user/save',params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },
  updateAdminUser(params){ //console.log(params);
    return axios.post(url + '/aacore/user/update',params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },
  updateAdminIP(params){ //console.log(params);
    return axios.post(url + '/aacore/user/update-allowed-ip', params).then(response =>response.data)
    .catch((error) => { return error.response });
  },
  changeStsAdmUser(params){ //console.log(params);
    return axios.post(url + '/aacore/user/change/state/'+params.status+'/' + params.userName).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },
  getUserGroupList(){ //console.log(params);
    return axios.post(url + '/aacore/admin/management/list/groups/0/10').then(response =>response.data)
    .catch((error) => { return error.response }); 
  },
  updateUserGroup(params){ //console.log(params);
    return axios.post(url + '/aacore/admin/groups/edit',params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },
  getUserRoleList(){ 
    return axios.post(url + '/aacore/admin/roles/list').then(response =>response.data)
    .catch((error) => { return error.response }); 
  },
  

};