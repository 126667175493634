<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>Edit Admin</h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

            <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
            </div>


            <CForm>           

              <CInput
                label="Username"
                v-model="userName"
                placeholder ="Enter your Username"
                horizontal
                :disabled="!isDisableduN"
              />

              <!--CInput
                label="Password"   
                v-model="password"           
                placeholder="Enter your password"
                type="password"
                horizontal
                autocomplete="current-password"
              /-->

              <CInput
                label="Email" 
                v-model="email"             
                placeholder="Enter your email"
                @change="onChangeEmail"
                type="email"
                horizontal
                autocomplete="email"
                :disabled="!isDisabledem"
              />

              <CInput
                label="Phone Number"
                v-model="phoneNumber"
                type="number"
                placeholder ="Enter your Phone Number"
                horizontal
                :disabled="!isDisabledpN"
              />

              <CInput
                label="First Name"
                v-model="firstName"
                placeholder ="Enter your First Name"
                horizontal
                :disabled="!isDisabledfN"
              />

              <CInput
                label="Last Name"
                v-model="lastName"
                placeholder ="Enter your Last Name"
                horizontal
                :disabled="!isDisabledlN"
              />

              <!--b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >User Status</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="status" :options="optStatus"></b-form-select>
                   </b-col>                  
              </b-row><br/-->

              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >User Group</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="groups" :options="optGroup" :disabled="!isDisabledgrp"></b-form-select>
                   </b-col>                  
              </b-row><br/>

              <b-row>
                <b-col sm="3">
                  <label for="input-small">Whitelist IP</label>
                </b-col>
                <b-col sm="9">
                  <multiselect :taggable="true" @tag="addTag" v-model="allowedIps" :options="optAllowedIps" :multiple="true" :showNoOptions="false" placeholder="IP Whitelist" label="name" track-by="code">
                    <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                  </multiselect>
                  <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new IP</label>
                </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="9"></b-col>   
                  <b-col sm="3"> 
                       <b-button-group>
                         <router-link to="/useradmin" tag="b-button">Cancel</router-link>
                         <b-button v-if="seenEBtn"  v-on:click="onChange()" variant="warning">Edit</b-button>
                         <b-button v-if="seenUBtn" v-on:click="UpdateUser()" variant="primary">Update</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>
              
            </CForm>
         
           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
 import AuthService from '@/api/AuthService.js';
 import AdminService from '@/api/AdminService.js';
 import Multiselect from "vue-multiselect";

  export default {
    components: {
      Multiselect
    },
    data() {
      return {  
          spinner :false,
          seenEBtn:false,
          seenUBtn:true,
          isDisableduN:false,
          isDisabledem:true,
          isDisabledpN:false,
          isDisabledfN:true,
          isDisabledlN:true,
          isDisabledgrp:true,
          userName: this.$route.params.name,
          //password:'', 
          email:'',
          validemail:false, 
          phoneNumber:'',
          firstName:'',
          lastName:'',
          status:'',
          groups:'',
          allowedIps:[],
          optAllowedIps:[],
          optStatus:[], 
          optGroup:[],
          seen:false,
          msg :'',
          color:''
          
        } ;      
      
    },
    created () {           
       this.spinner = true;
       this.getConfigList();  
       this.getUserGroupList(); 
      //  var init   = [];  let params = {};
      //  init.push('admin'); init.push('super_admin'); params['groupList'] = init; 
      //  var result = JSON.stringify(params);
      //  this.getUserAdminList(result); 
              
       setTimeout( () =>  this.showAllAdminList() , 5000); 

    },
    methods: {

      showAllAdminList(){          
          let params = {}; params['groupList'] = this.optGroup; 
          var result = JSON.stringify(params);
          this.getUserAdminList(result); 
          
      },

      onChange(){

         //this.isDisableduN=true;
         this.isDisabledem=true;
         //this.isDisabledpN=true;
         this.isDisabledfN=true;
         this.isDisabledlN=true;
         this.isDisabledgrp=true;
         this.seenEBtn=false;
         this.seenUBtn=true;
      },

      UpdateUser(){

        let params = {}; var groups = []; var validate = [];

        params['userName'] = this.userName;        
        params['phoneNumber'] = this.phoneNumber;
        params['firstName'] = this.firstName;
        params['lastName'] = this.lastName;
        params['email']   = this.email;
        params['status'] = "ACTIVE";
        groups.push(this.groups);
        params['groups'] = groups;

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false)){   //console.log(params);           
            if(localStorage.getItem('sa')==="true"){  
              this.updateAdminUser(params);
            } else {
              this.msg   = 'Sorry, your role not allowed this action!'; 
              this.color = 'warning'; this.seen  = true;
            }
        } else {
           this.msg   = 'Data Not Complete or Invalid Email!'; 
           this.color = 'warning'; this.seen  = true;          
        } 

        setTimeout( () =>  this.seen=false , 5000);

      },

      onChangeEmail(){
          var email = this.email;
          var validate = this.checkEmail(email);
          this.validemail = validate;         
      },

      checkEmail(email) {
          var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
          if (!reg.test(email)) return false;
          return true;
      },      

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {         

            //this.optStatus = resp.userStatus;
            
           }, error => {
            console.log('error');    
          });
      }, 

      getUserGroupList: function() { 
          AdminService.getUserGroupList().then(resp => {             
            var src = resp.payload; var temp =[];

            for (var key in src) {  
              var name = src[key].name;
              if(name!=="user") { temp.push(name); }
            }

            this.optGroup = temp;  

           }, error => {
            console.log('error');    
          });
      },

      addTag (newTag) {
        // if (newTag.)
        let regex = /^[a-zA-Z0-9-_,.@#* \b]+$/
        if (!regex.test(newTag)) {
          alert("some of the tag has disallowed character")
        } else {
          const tag = {
            name: newTag,
            code: newTag
          }
          this.allowedIps.push(tag)
        }
      },

      updateAdminUser: function(params) { 
          AdminService.updateAdminUser(params).then(resp => { 
            //console.log(resp);        

            if(resp.status=='OK'){
                this.msg   = 'Update Admin successfully'; 
                this.color = 'success';  this.seen  = true;

                 this.isDisableduN=false;
                 this.isDisabledem=false;
                 this.isDisabledpN=false;
                 this.isDisabledfN=false;
                 this.isDisabledlN=false;
                 this.isDisabledgrp=false;
                 this.seenEBtn=true;
                 this.seenUBtn=false;
                 let paramIps = {};
                 let listAllowedIps = [];
                 this.allowedIps.forEach((valIps, keyIps)=>{
                    listAllowedIps.push(valIps["code"]);
                 });
                 paramIps['userName'] = this.userName;
                 paramIps['allowedIps'] =  (listAllowedIps.length > 0) ? listAllowedIps.join(",") : '';
                 this.updateAdminIP(paramIps);
              } else {
                this.msg   = !resp.data.message ? "Update Admin Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
              }
            
           }, error => {
            console.log('error');    
          });
      },

      updateAdminIP: function(params) {
          AdminService.updateAdminIP(params).then(resp => {
            //console.log(resp);

            if(resp.status=='OK'){
                this.msg   = 'Update Admin successfully';
                this.color = 'success';  this.seen  = true;

                 this.isDisableduN=false;
                 this.isDisabledem=false;
                 this.isDisabledpN=false;
                 this.isDisabledfN=false;
                 this.isDisabledlN=false;
                 this.isDisabledgrp=false;
                 this.seenEBtn=true;
                 this.seenUBtn=false;

              } else {
                this.msg   = !resp.data.message ? "Update Admin Failed!" : resp.data.message;
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);
              }

           }, error => {
            console.log('error');
          });
      },

      getUserAdminList: function(params) {
        AdminService.getUserAdminList(params).then(resp => { //console.log(resp);

          let selectData = {};   var list = resp.payload;
          for (var key in list) { 
            var selected = this.userName;
            var userName = list[key].userName;
            if(selected==userName){             
              this.phoneNumber = list[key].phoneNumber;
              this.firstName   = list[key].firstName;
              this.lastName    = list[key].lastName;
              this.status      = list[key].status;
              this.email       = list[key].email;
              var group        = list[key].groups;
              this.groups      = group[0];
              if (list[key].hasOwnProperty('allowedIps') && list[key].allowedIps !== "") {
                let listIP = list[key].allowedIps.split(",");
                let listAllowedIps = [];
                listIP.forEach((valIp, indexIp) => {
                  listAllowedIps.push({code: valIp.toString(), name: valIp.toString()});
                });
                this.allowedIps = listAllowedIps;
              }
            }            
          }

          this.spinner = false;

        }, error => {
          this.loading = false;
        });
      }       
      
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
